import sortOn from 'sort-on'
import api from 'api'

import { clearDriverMap } from 'src/redux/driver-map/actions'
import { clearDriverInventoryMap, updateDriverInventoryMap } from 'src/redux/driver-inventory-map/actions'
import { fetchDriverInventory } from 'src/redux/driver-inventory-map/operations'
import { clearDriverOrdersMap, updateDriverOrdersMap } from 'src/redux/driver-orders-map/actions'
import { fetchDriverOrders } from 'src/redux/driver-orders-map/operations'
import { determineTimeStampForDay } from 'helpers/date'
import { ERROR_USER_ALREADY_DRIVER, CLOSE_DRIVER_DRAWER, DEFAULT_DRIVER_CONTENT_TAB } from 'helpers/drivers'
import {
  clearDriversStore,
  setFocusedDriversDriverId,
  setFocusedDriverDepotHasMETRCRules,
  setDriverInventoryLoadingMap,
  setDriverOrdersLoadingMap,
  setDriverDetailsContent
} from 'src/redux/drivers/actions'
import { clearDispensariesStore, fetchSpecificDepotDrivers } from 'src/redux/dispensaries/actions'
import {
  getDriverInventoryLoadingMap,
  getDriverOrdersLoadingMap,
  getFocusedDriverDepotId
} from 'src/redux/drivers/selectors'
import { ERROR, pushNotification, SUCCESS } from 'src/redux/alerts/actions'
import { getDepotsMap } from 'src/redux/depots-map/selectors'
import { checkIfDepotHasMETRCRules } from 'src/redux/depots-map/operations'
import { fetchDriverVehicles } from '../driver-vehicles/operations'
import { changeDriverDepotId } from 'src/redux/driver-map/operations'
import { fetchUserForPromotion } from 'src/redux/users/actions'

export function clearDrivers () {
  return (dispatch) => {
    dispatch(clearDriverMap())
    dispatch(clearDriverInventoryMap())
    dispatch(clearDriverOrdersMap())
    dispatch(clearDriversStore())
    dispatch(clearDispensariesStore())
  }
}

export function hydrateFocusedDriversDriver (driverId) {
  return (dispatch) => {
    dispatch(fetchFocusedDriverInventory(driverId))
  }
}

export function fetchFocusedDriverInventory (driverId) {
  return async (dispatch, getState) => {
    dispatch(updateDriverInventoryLoadingMap(driverId, true))
    const { err, data } = await dispatch(fetchDriverInventory(driverId))
    dispatch(updateDriverInventoryLoadingMap(driverId, false))

    if (err) return
    const { inventory, inventoryChecksum } = data
    const sanitizedInventory = inventory.map(item => {
      if (!item.brand || !item.brand.name) {
        item.brand = { name: '' }
      }
      item.productType.name = item.productType.name || ''
      item.name = item.name || ''
      return item
    })
    const sortedInventory = sortOn(sanitizedInventory, ['productType.name', 'brand.name', 'productId'])

    dispatch(updateDriverInventoryMap({
      [driverId]: {
        inventory: sortedInventory,
        inventoryChecksum
      }
    }))
  }
}

export function updateDriverInventoryLoadingMap (driverId, isLoading) {
  return (dispatch, getState) => {
    const state = getState()
    const driverInventoryLoadingMap = getDriverInventoryLoadingMap(state)
    const newDriverInventoryLoadingMap = { ...driverInventoryLoadingMap }
    newDriverInventoryLoadingMap[driverId] = isLoading

    dispatch(setDriverInventoryLoadingMap(newDriverInventoryLoadingMap))
  }
}

/**
 * @param {number} driverId
 * @param {string} newDate ISO date only - 'YYYY-MM-DD'
 */
export function changeDriverHistoryDate (driverId, newDate) {
  return async (dispatch, getState) => {
    // need depot timezone - don't want to drill it through components, getting value from state
    const { depotsMap, driverMap } = getState()
    const driverDepotId = driverMap[driverId].depotId
    const depotTimeZone = depotsMap[driverDepotId].timeZone

    // filtering by the acceptedAt date
    const payload = {
      driverId,
      // page: 1,
      // statuses: ORDER_STATUSES.PENDING,
      from_search_date: encodeURI(determineTimeStampForDay(newDate, depotTimeZone, true)),
      to_search_date: encodeURI(determineTimeStampForDay(newDate, depotTimeZone, false))
    }
    dispatch(updateDriverOrdersLoadingMap(driverId, true))
    const { err, data } = await dispatch(fetchDriverOrders(payload))
    dispatch(updateDriverOrdersLoadingMap(driverId, false))

    if (err) return
    const { items } = data

    dispatch(updateDriverOrdersMap({ [driverId]: items }))
  }
}

export function updateDriverOrdersLoadingMap (driverId, isLoading) {
  return (dispatch, getState) => {
    const state = getState()
    const isDriverOrdersLoadingMap = getDriverOrdersLoadingMap(state)
    const newDriverOrdersLoadingMap = { ...isDriverOrdersLoadingMap }
    newDriverOrdersLoadingMap[driverId] = isLoading

    dispatch(setDriverOrdersLoadingMap(newDriverOrdersLoadingMap))
  }
}

/**
 * @param {number} driverId
 * @param {number} depotId
 */
export function setFocusedDriversDriver (driverId, depotId = null) {
  return async function (dispatch, getState) {
    dispatch(setFocusedDriversDriverId(driverId))

    // since these driver IDs are ints, we need to ensure we honor an ID of 0
    if (driverId || driverId === 0) {
      const state = getState()
      const focusedDriverDepotId = getFocusedDriverDepotId(state)
      const depotsMap = getDepotsMap(state)
      const depot = !depotId && focusedDriverDepotId ? depotsMap[focusedDriverDepotId] : depotId ? depotsMap[depotId] : {}
      if (Object.keys(depot).length !== 0) {
        const depotHasMETRCRules = await dispatch(checkIfDepotHasMETRCRules(depot))
        dispatch(fetchDriverVehicles(driverId))
        dispatch(setFocusedDriverDepotHasMETRCRules(depotHasMETRCRules))
        dispatch(hydrateFocusedDriversDriver(driverId))
      } else {
        // if we do not have a depot id present at all, we will close the driver detail page to avoid errors
        dispatch(setDriverDetailsContent(DEFAULT_DRIVER_CONTENT_TAB))
        dispatch(setFocusedDriversDriver(CLOSE_DRIVER_DRAWER))
        dispatch(setFocusedDriverDepotHasMETRCRules(undefined))
      }
    }
  }
}

export function updateDriverDepot (driverId, depotId, dispensaryId) {
  return async (dispatch, getState) => {
    const payload = {
      depotIds: [depotId],
      dispensaryId,
      id: driverId,
      userId: driverId
    }

    const { err } = await api.updateDriverDepot(payload)

    if (err) return dispatch(pushNotification(`Error updating driver ${driverId}'s depot`, ERROR))

    const state = getState()
    const depotsMap = getDepotsMap(state)
    const newDepot = depotsMap[depotId]
    const depotHasMETRCRules = await dispatch(checkIfDepotHasMETRCRules(newDepot))
    dispatch(setFocusedDriverDepotHasMETRCRules(depotHasMETRCRules))
    dispatch(changeDriverDepotId(driverId, depotId))

    return dispatch(pushNotification(`Driver ${driverId} assigned to ${newDepot.name}`, SUCCESS))
  }
}
/**
 * Submits two API calls in an attempt to make a user into a driver, then assign
 * them to an existing depot
 * @export
 * @function
 * @param {string} driverEmail
 * @param {number} depotId
 * @returns {Object} { success: boolean, message: string }
 */
export function addDriverToDepot (driverEmail, depotId) {
  return async (dispatch, getState) => {
    const state = getState()
    const depotsMap = getDepotsMap(state)
    const depot = depotsMap[depotId]
    const dispensaryId = depot.dispensaryId
    let result = {
      success: false,
      message: 'An error occurred. Please try again or seek additional help.'
    }

    const driverUserObject = await dispatch(fetchUserForPromotion({
      query: driverEmail
    }))

    if (!driverUserObject) {
      dispatch(pushNotification(`No user found with email, "${driverEmail}." \nPlease make sure the email is correct and registered to an Eaze account.`, ERROR))
      result.message = 'User not found.'
      return result
    }

    const driverId = driverUserObject.id
    const payload = {
      depotIds: [depotId],
      dispensaryId,
      id: driverId,
      userId: driverId
    }

    // clean up api.js error handling exception when this and the updateDriverDepot
    // endpoint are consolidated into one
    const { err: errDriverPromotion } = await api.promoteToDriver(payload)
    const wasAlreadyDriver = errDriverPromotion?.message === ERROR_USER_ALREADY_DRIVER

    if (!errDriverPromotion || wasAlreadyDriver) {
      const { err } = await api.updateDriverDepot(payload)
      if (!err) {
        result = {
          success: true,
          message: wasAlreadyDriver ? `Driver, ${driverEmail}, was assigned to ${depot.name}.` : `User, ${driverEmail}, was promoted to Driver and assigned to ${depot.name}.`
        }
        dispatch(pushNotification(result.message, SUCCESS))
        dispatch(fetchSpecificDepotDrivers(depotId))
        if (!wasAlreadyDriver) {
          // open up the driver info page for the new drivers vehicle to be added as a part of onboarding
          dispatch(setFocusedDriversDriver(driverId, depotId))
        }
      }
    }
    return result
  }
}
