import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/styles/makeStyles'
import { capitalize } from 'src/utils/helpers'
import SORT_OPTIONS, { FILTER_OPTIONS } from './sortOptions'

const useStyles = makeStyles((theme) => {
  const { pxToRem } = theme.typography
  return {
    wrap: {
      backgroundColor: '#313232',
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: pxToRem(1200),
      padding: 0,
      marginTop: pxToRem(36),
      marginBottom: pxToRem(36),
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    leftSide: {
      backgroundColor: '#313232',
      display: 'flex',
      justifyContent: 'space-between',
      width: pxToRem(360)
    },
    stateFilter: {
      width: pxToRem(100)
    },
    isEcommerceFilter: {
      width: pxToRem(340),
      marginLeft: pxToRem(12)
    },
    sortDirection: {
      width: pxToRem(240)
    },
    searchInput: {
      width: pxToRem(360)
    }
  }
})

/**
 * Will submit the text present in the search input if a user presses enter
 * while the input is focused
 * @curry
 * @function
 * @param {func} setCatalogSearch - See CatalogAPIControls.propTypes
 * @param {Object} event - onKeyPress event object
 * @returns null
 */
const submitSearchQuery = (event = {}, setCatalogSearch, searchQuery) => {
  if (event.key === 'Enter') {
    const { target = {} } = event
    const { dataset = {} } = target
    const { route } = dataset
    const { value, state } = searchQuery
    if (route) setCatalogSearch(value, route, state)
  }
}

/**
 * Will apply sorting based on the selected option
 * Note: sorting is done in redux (selectors), we don't leverage the API yet
 * @curry
 * @function
 * @param {func} setCatalogSorting - See CatalogAPIControls.propTypes
 * @param {Object} event - onChange event object
 * @returns null
 */
const submitCatalogSorting =
  (setCatalogSorting) =>
    (event = {}) => {
      const { currentTarget = {}, target = {} } = event
      const { dataset = {} } = currentTarget
      const { route } = dataset
      const { value: sortType = '-updated_at' } = target

      setCatalogSorting(sortType, route)
    }

/**
 * An interface for managing parameters we send to the Catalog APIs
 *
 * Features:
 * - Search input
 *   - Submitted on `Enter` press
 *   - Does not render for APIs without search support:
 *     - Menus: https://eaze.github.io/pine/#!/catalog/get_catalog_menus
 * - State filter select
 *   - States are sorted alphabetically by default
 *   - Only renders for APIs with `stateIds` support:
 *     - Products: https://eaze.github.io/pine/#!/catalog/get_catalog_items
 *     - Groups: https://eaze.github.io/pine/#!/catalog/get_catalog_groups
 *     - Menus: https://eaze.github.io/pine/#!/catalog/get_catalog_menus
 * - Sort By
 *   - Defaults to `-updated_at`, i.e. "Most recently updated"
 *   - When sort changes, if a search was previously applied, it will be lost;
 *     the Catalog Search API does NOT support the `stateIds` param:
 *     https://eaze.github.io/pine/#!/catalog/get_catalog_search_models
 * - When `currentRoute` changes:
 *   - Search text is cleared
 *   - State filter is persisted for supported APIs
 *   - Sort By is persisted for old route (but is not applied for the new route)
 *
 * Note: This UI is dependent on the configs defined in ROUTES & SORT_OPTIONS.
 */
function CatalogAPIControls(props) {
  const classes = useStyles(props)
  const {
    availableStates = [],
    currentCatalogSorting = {},
    currentRoute = '',
    currentStateFilter = '',
    currentIsEcommerceFilter = '',
    setCatalogSearch = () => {},
    setCatalogSorting = () => {},
    setCatalogStateFilter = () => {},
    setCatalogEcommerceFilter = () => {}
  } = props

  const [searchQuery, setSearchQuery] = React.useState({
    value: '',
    state: ''
  })

  React.useEffect(() => {
    // If the currentRoute changed, reset the current search query
    const state = ['products', 'groups', 'menus'].includes(currentRoute) ? currentStateFilter : ''
    setSearchQuery({ value: '' })
    setCatalogStateFilter(state)
  }, [currentRoute])

  React.useEffect(() => {
    setSearchQuery({ value: '', state: currentStateFilter })
  }, [currentStateFilter])

  const sortOptions = SORT_OPTIONS[currentRoute]
  const currentSorting = currentCatalogSorting[currentRoute] || ''
  const filterOptions = FILTER_OPTIONS[currentRoute] || []

  /**
   * API returns a lot of objects for the same state because they represent
   * different regions of the state; we need to pull the state shortnames out,
   * then sort them; memoizing will reduce unnecessary computations
   */
  const stateShortNames = React.useMemo(() => {
    return availableStates.reduce(
      (states, { state }) => {
        if (!states.includes(state)) states.push(state)
        return states
      },
      ['']
    )
  }, [availableStates])

  const sortedStateShortnames = React.useMemo(() => {
    return stateShortNames.sort()
  }, [stateShortNames])

  return (
    <Paper className={classes.wrap} elevation={0}>
      <Paper className={classes.leftSide} elevation={0}>
        <TextField
          className={classes.sortDirection}
          data-testid="sort-direction-select-wrap"
          label="Sort By"
          onChange={submitCatalogSorting(setCatalogSorting)}
          select
          SelectProps={{
            SelectDisplayProps: {
              // must click this to create "focus" / open menu
              'data-testid': 'sort-direction-select'
            }
          }}
          variant="filled"
          value={currentSorting}
        >
          {sortOptions.map(({ label, value }) => (
            <MenuItem data-route={currentRoute} value={value} key={label}>
              {label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          className={classes.isEcommerceFilter}
          data-testid="isEcommerce-filter"
          name={'Ecommerce Filter'}
          label="Filter By"
          onChange={setCatalogEcommerceFilter}
          select
          SelectProps={{
            SelectDisplayProps: {
              // must click this to create "focus" / open menu
              'data-testid': 'isEcommerce-filter-select'
            }
          }}
          variant="filled"
          value={currentIsEcommerceFilter || 'All'}
        >
          {filterOptions.map(({ label, value }) => (
            <MenuItem data-route={currentRoute} value={value} key={label}>
              {label}
            </MenuItem>
          ))}
        </TextField>

        {['products', 'groups', 'menus'].includes(currentRoute) && (
          <TextField
            className={classes.stateFilter}
            data-testid="state-filter-select-wrap"
            label="State"
            onChange={setCatalogStateFilter}
            select
            SelectProps={{
              SelectDisplayProps: {
                // must click this to create "focus" / open menu
                'data-testid': 'state-filter-select'
              }
            }}
            variant="filled"
            value={currentStateFilter || 'All'}
          >
            {sortedStateShortnames.map((state) => (
              <MenuItem value={state || 'All'} key={state}>
                {state || 'All'}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Paper>

      {currentRoute !== 'menus' && (
        <TextField
          className={classes.searchInput}
          data-testid="search-input-wrap"
          inputProps={{
            'data-route': currentRoute,
            'data-testid': 'search-input'
          }}
          label={`Search ${capitalize(currentRoute)}`}
          onChange={(event) =>
            setSearchQuery({
              value: event.target.value,
              state: currentStateFilter
            })
          }
          onKeyPress={(event) =>
            submitSearchQuery(event, setCatalogSearch, searchQuery)
          }
          variant="filled"
          value={searchQuery.value}
        />
      )}
    </Paper>
  )
}

CatalogAPIControls.propTypes = {
  /**
   * @description Array of objs representing states available, per the API
   * @returns {array} rdxStore.catalog.statesAvailable getCatalogStatesAvailable
   */
  availableStates: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.string // 'CA'
    })
  ),
  /**
   * @description A mapping of the current sorting applied across Catalog pages
   * @returns {Object} {
   *   brands: "-updated_at",
   *   groups: "-updated_at",
   *   menus: "-updated_at",
   *   products: "-updated_at",
   *   strains: "-updated_at",
   *   subtypes: "-updated_at",
   *   types: "-updated_at"
   * }
   */
  currentCatalogSorting: PropTypes.object,
  /**
   * @description Name of state currently applied as data set filter
   * @returns {string} state name, e.g. "CA"
   */
  currentStateFilter: PropTypes.string,
  /**
   * @description Value of currently applied filter for isEcommerce property on a brand
   * @returns {bool} boolean value, e.g. "true || false"
   */
  currentIsEcommerceFilter: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  /**
   * @description Represents which Catalog API to fetch from
   * @returns {string} "products", "groups", "menus", etc
   */
  currentRoute: PropTypes.string,
  /**
   * Leverages a redux action: src/redux/catalog/actions > mapToCatalogSearch(),
   * which invokes searchCatalogEntity("cbd", "products")
   * @param {string} searchQuery - text to search for; "cbd"
   * @param {string} currentRoute - catalog subview; "products", "menus", etc.
   * @description Applies the desired search for the current Catalog subview
   * @returns {null}
   */
  setCatalogSearch: PropTypes.func,
  /**
   * Leverages a redux action: src/redux/catalog/actions > mapToCatalogSort(),
   * which invokes setCatalogSort("-updated_at", "menus")
   * @param {string} sortIndicator - sort direction and field; "-updated_at"
   * @param {string} currentRoute - catalog subview; "products", "menus", etc.
   * @description Applies the desired sorting for the current Catalog subview
   * @returns {null}
   */
  setCatalogSorting: PropTypes.func,
  /**
   * @param {Object} event - onChange event object as a param
   * @description Updates respective store to apply state as a data set filter
   * @returns {null}
   */
  setCatalogStateFilter: PropTypes.func,
  /**
   * @param {Object} event - onChange event object as a param
   * @description Updates value apply for isEcommerce API call
   * @returns {null}
   */
  setCatalogEcommerceFilter: PropTypes.func
}

export default CatalogAPIControls
