/**
 * Setting up the catalog container
 */

import qs from 'query-string'
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

import { FORM_STRING_TYPES, SPECIES_CATALOG_TYPE } from 'src/helpers/constants'
import { toggleActionBar } from 'src/redux/catalog/actions.common.js'
import {
  applyCatalogEcommerceFilter,
  applyCatalogStateFilter,
  confirmSaveProduct,
  fetchAndSetCatalogItem,
  fetchLocationStates,
  hideProductConfirmDrawer,
  mapToCatalogFetch,
  mapToCatalogSearch,
  mapToCatalogSort,
  setProductConfirmDrawerReasons,
  showProductConfirmDrawer
} from 'src/redux/catalog/actions'
import {
  createCatalogItem,
  createInventoryItems,
  deleteCatalogItem,
  hideProductForm,
  resetSkippedDepots,
  setCatalogItem,
  showProductForm,
  submitCatalogItem,
  updateCatalogItem
} from 'src/redux/catalog/item/actions'
import {
  createCatalogType,
  deleteCatalogType,
  hideTypeForm,
  setCatalogType,
  showTypeForm,
  updateCatalogType
} from 'src/redux/catalog/type/actions'
import {
  createCatalogSubtype,
  deleteCatalogSubtype,
  hideSubtypeForm,
  setCatalogSubtype,
  showSubtypeForm,
  updateCatalogSubtype
} from 'src/redux/catalog/subtype/actions'
import {
  createCatalogStrain,
  deleteCatalogStrain,
  hideStrainForm,
  setCatalogStrain,
  showStrainForm,
  updateCatalogStrain
} from 'src/redux/catalog/strain/actions'

import { uploadPublicFile } from 'src/redux/files/api'

import { pushNotification } from 'src/redux/alerts/actions'
import { fetchAllDispensaries } from 'src/redux/dispensaries/actions'
import { getActiveDispensaries } from 'src/redux/dispensaries/selectors'

import {
  getCatalogEcommerceFilter,
  getCatalogIsActionBarOpen,
  getCatalogIsProductConfirmDrawerOpen,
  getCatalogIsProductFormOpen,
  getCatalogIsStrainFormOpen,
  getCatalogIsSubtypeFormOpen,
  getCatalogIsTypeFormOpen,
  getCatalogProductConfirmDrawerReasons,
  getCatalogSort,
  getCatalogSpecies,
  getCatalogStateFilter,
  getCatalogStatesAvailable,
  getCatalogStrains,
  getCatalogSubtypes,
  getCatalogTypes,
  getIsBulkCreateActive,
  getSortedCatalogBrands,
  getSortedCatalogGroups,
  getSortedCatalogMenus,
  getSortedCatalogProducts,
  getSortedCatalogStrains,
  getSortedCatalogSubtypes,
  getSortedCatalogTypes
} from 'src/redux/catalog/selectors'
import {
  getLoadingCatalog,
  getLoadingProducts
} from 'src/redux/loading/selectors'

import {
  cantCreateProduct,
  getCatalogItem,
  getSkippedDepots
} from 'src/redux/catalog/item/selectors'
import { cantCreateType } from 'src/redux/catalog/type/selectors'
import { cantCreateSubtype } from 'src/redux/catalog/subtype/selectors'
import { cantCreateStrain } from 'src/redux/catalog/strain/selectors'

import { resetBrandItem } from 'src/redux/brand-item/actions'
import {
  createOrUpdateGroup,
  resetGroupItem,
  updateGroupObject
} from 'src/redux/group-item/actions'
import { resetMenuItem } from 'src/redux/menu-item/actions'
import ROUTES from 'src/pages/catalog/routes'

import Catalog from './'
import { array, bool, func, string } from 'prop-types'

// Props passed to the component
const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { view: currentRoute }
    }
  } = props

  const { catalogFormType = '', catalogFormId = '' } = qs.parse(
    props.location.search
  )
  return {
    activeDispensaries: getActiveDispensaries(state),
    // used for editing a product, strain, type or subtype via query params
    pathname: props.location.pathname,
    catalogFormType,
    catalogFormId,
    skippedDepots: getSkippedDepots(state),
    products: getSortedCatalogProducts(state),
    brands: getSortedCatalogBrands(state),
    groups: getSortedCatalogGroups(state),
    menus: getSortedCatalogMenus(state),
    types: getCatalogTypes(state),
    subtypes: getCatalogSubtypes(state),
    strains: getCatalogStrains(state),
    species: getCatalogSpecies(state),
    statesAvailable: getCatalogStatesAvailable(state),
    currentStateFilter: getCatalogStateFilter(state),
    currentIsEcommerceFilter: getCatalogEcommerceFilter(state),

    strainsSorted: getSortedCatalogStrains(state),
    typesSorted: getSortedCatalogTypes(state),
    subtypesSorted: getSortedCatalogSubtypes(state),

    productConfirmDrawerReasons: getCatalogProductConfirmDrawerReasons(state),
    isProductFormOpen: getCatalogIsProductFormOpen(state),
    isTypeFormOpen: getCatalogIsTypeFormOpen(state),
    isSubtypeFormOpen: getCatalogIsSubtypeFormOpen(state),
    isStrainFormOpen: getCatalogIsStrainFormOpen(state),
    isActionBarOpen: getCatalogIsActionBarOpen(state),
    isProductConfirmDrawerOpen: getCatalogIsProductConfirmDrawerOpen(state),
    catalogItem: getCatalogItem(state),
    catalogType: state.catalogType,
    catalogSubtype: state.catalogSubtype,
    catalogStrain: state.catalogStrain,
    currentRoute,

    catalogSort: getCatalogSort(state),

    cantCreateProduct: cantCreateProduct(state),
    cantCreateType: cantCreateType(state),
    cantCreateSubtype: cantCreateSubtype(state),
    cantCreateStrain: cantCreateStrain(state),
    loadingCatalog: getLoadingCatalog(state),
    loadingProducts: getLoadingProducts(state),
    isBulkCreateActive: getIsBulkCreateActive(state)
  }
}

// Methods passed to the components
const mapDispatchToProps = (dispatch, props) => {
  const { location: routerLocation } = props
  return {
    fetchLocationStates: () => dispatch(fetchLocationStates()),
    // catalog actions
    fetchCatalogFn: (type) => dispatch(mapToCatalogFetch(type)),
    searchCatalogFn: (value, type, state) =>
      dispatch(mapToCatalogSearch(value, type, state)),
    applyCatalogStateFilter: (event) =>
      dispatch(applyCatalogStateFilter(event)),
    applyCatalogEcommerceFilter: (event) =>
      dispatch(applyCatalogEcommerceFilter(event)),

    fetchAndSetCatalogItem: (id) => dispatch(fetchAndSetCatalogItem(id)),
    toggleActionBar: () => dispatch(toggleActionBar()),
    setCatalogSortFn: (value, type) => dispatch(mapToCatalogSort(value, type)),
    createOrUpdateGroup: () => dispatch(createOrUpdateGroup()),
    updateGroupObject: (groupObject) =>
      dispatch(updateGroupObject(groupObject)),

    // catalog item (product) actions
    fetchAllDispensaries: () => dispatch(fetchAllDispensaries()),
    createCatalogItem: () => dispatch(createCatalogItem()),
    createInventoryItems: (id, dispensaries) =>
      dispatch(createInventoryItems(id, dispensaries)),
    updateCatalogItem: () => dispatch(updateCatalogItem(routerLocation)),
    submitCatalogItem: () => dispatch(submitCatalogItem(routerLocation)),
    deleteCatalogItem: () => dispatch(deleteCatalogItem(routerLocation)),
    setCatalogItem: (v) => dispatch(setCatalogItem(v)),
    hideProductForm: () => dispatch(hideProductForm(routerLocation)),
    showProductForm: (product) => dispatch(showProductForm(product)),
    showProductConfirmDrawer: () => dispatch(showProductConfirmDrawer()),
    hideProductConfirmDrawer: () => dispatch(hideProductConfirmDrawer()),
    confirmSaveProduct: () => dispatch(confirmSaveProduct(routerLocation)),
    setProductConfirmDrawerReasons: (reasons) =>
      dispatch(setProductConfirmDrawerReasons(reasons)),
    resetSkippedDepots: () => dispatch(resetSkippedDepots()),

    // catalog type actions
    createCatalogType: () => dispatch(createCatalogType()),
    updateCatalogType: () => dispatch(updateCatalogType(routerLocation)),
    deleteCatalogType: () => dispatch(deleteCatalogType(routerLocation)),
    setCatalogType: (v) => dispatch(setCatalogType(v)),
    hideTypeForm: () => dispatch(hideTypeForm(routerLocation)),
    showTypeForm: (type) => dispatch(showTypeForm(type)),

    // catalog subtype actions
    createCatalogSubtype: () => dispatch(createCatalogSubtype()),
    updateCatalogSubtype: () => dispatch(updateCatalogSubtype(routerLocation)),
    deleteCatalogSubtype: () => dispatch(deleteCatalogSubtype(routerLocation)),
    setCatalogSubtype: (v) => dispatch(setCatalogSubtype(v)),
    hideSubtypeForm: () => dispatch(hideSubtypeForm(routerLocation)),
    showSubtypeForm: (subtype) => dispatch(showSubtypeForm(subtype)),

    // catalog strain actions
    createCatalogStrain: () => dispatch(createCatalogStrain()),
    updateCatalogStrain: () => dispatch(updateCatalogStrain(routerLocation)),
    deleteCatalogStrain: () => dispatch(deleteCatalogStrain(routerLocation)),
    setCatalogStrain: (v) => dispatch(setCatalogStrain(v)),
    hideStrainForm: () => dispatch(hideStrainForm(routerLocation)),
    showStrainForm: (strain) => dispatch(showStrainForm(strain)),

    // reset actions
    resetBrandItem: () => dispatch(resetBrandItem()),
    resetGroupItem: () => dispatch(resetGroupItem()),
    resetMenuItem: () => dispatch(resetMenuItem()),

    pushNotification: (message, type) =>
      dispatch(pushNotification(message, type)),
    uploadPublicFile: (formData) => dispatch(uploadPublicFile(formData))
  }
}

class CatalogContainer extends PureComponent {
  static propTypes = {
    currentRoute: string,
    fetchCatalogFn: func,
    fetchLocationStates: func,
    fetchAllDispensaries: func,
    searchCatalogFn: func,
    applyCatalogStateFilter: func,
    catalogFormType: string,
    catalogFormId: string,
    products: array,
    types: array,
    subtypes: array,
    strains: array,
    isProductFormOpen: bool,
    isTypeFormOpen: bool,
    isSubtypeFormOpen: bool,
    isStrainFormOpen: bool,
    loadingProducts: bool,
    fetchAndSetCatalogItem: func,
    showProductForm: bool,
    showTypeForm: bool,
    showSubtypeForm: bool,
    showStrainForm: bool
  };

  state = {
    didFetchProduct: false
  };

  componentDidMount() {
    const {
      currentRoute,
      fetchCatalogFn,
      fetchAllDispensaries,
      fetchLocationStates
    } = this.props
    fetchCatalogFn(currentRoute)
    fetchLocationStates() // We need the states for the state filter

    if (currentRoute === ROUTES.products.name) {
      // the create/edit product overlay needs the following catalog types for dropdowns
      fetchCatalogFn(ROUTES.brands.name)
      fetchCatalogFn(ROUTES.strains.name)
      fetchCatalogFn(ROUTES.subtypes.name)
      fetchCatalogFn(ROUTES.types.name)
      fetchCatalogFn(SPECIES_CATALOG_TYPE)
      // except this one, this one is for copying products
      fetchAllDispensaries()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      catalogFormType,
      catalogFormId,
      currentRoute,
      fetchCatalogFn,
      fetchLocationStates,
      fetchAllDispensaries
    } = this.props

    const { currentRoute: prevRoute } = prevProps

    if (prevRoute !== currentRoute && currentRoute === ROUTES.products.name) {
      // the create/edit product overlay needs the following catalog types for dropdowns
      fetchCatalogFn(ROUTES.brands.name)
      fetchCatalogFn(ROUTES.strains.name)
      fetchCatalogFn(ROUTES.subtypes.name)
      fetchCatalogFn(ROUTES.types.name)
      fetchCatalogFn(SPECIES_CATALOG_TYPE)
      fetchLocationStates()
      // except this one, this one is for copying products
      fetchAllDispensaries()
    }

    if (
      catalogFormId &&
      catalogFormType &&
      currentRoute.includes(catalogFormType)
    ) {
      this.handleFormEdit(catalogFormType, catalogFormId)
    }
  }

  handleFormEdit = (catalogFormType, catalogFormId) => {
    const { didFetchProduct } = this.state
    const {
      products,
      types,
      subtypes,
      strains,
      isProductFormOpen,
      isTypeFormOpen,
      isSubtypeFormOpen,
      isStrainFormOpen,
      loadingProducts,
      fetchAndSetCatalogItem
    } = this.props

    const { STRAIN, PRODUCT, TYPE, SUBTYPE } = FORM_STRING_TYPES

    const itemsMap = {
      [PRODUCT]: products,
      [TYPE]: types,
      [SUBTYPE]: subtypes,
      [STRAIN]: strains
    }

    const isItemFormOpenMap = {
      [PRODUCT]: isProductFormOpen,
      [TYPE]: isTypeFormOpen,
      [SUBTYPE]: isSubtypeFormOpen,
      [STRAIN]: isStrainFormOpen
    }

    const items = itemsMap[catalogFormType] || []
    const item = items.find(
      (item) => item.id.toLowerCase() === catalogFormId.toLowerCase()
    )
    const isItemFormOpen = isItemFormOpenMap[catalogFormType]
    // we only have a way to look up a product by ID currently so we are only going to do this for type product
    const hasFormQueryParams = catalogFormType && catalogFormId
    const hasLoadedItems = items.length && !loadingProducts
    const isTypeProduct = catalogFormType === PRODUCT
    const shouldFetchItem =
      isTypeProduct &&
      hasFormQueryParams &&
      hasLoadedItems &&
      !item &&
      !didFetchProduct

    if (shouldFetchItem) {
      this.setState({ didFetchProduct: true }, () =>
        fetchAndSetCatalogItem(catalogFormId)
      )
    }

    // fill and open form if the item to edit exists and only if the form isn't already open
    if (item && isItemFormOpen === false) {
      const { showProductForm, showTypeForm, showSubtypeForm, showStrainForm } =
        this.props
      const actionsMap = {
        [PRODUCT]: showProductForm,
        [TYPE]: showTypeForm,
        [SUBTYPE]: showSubtypeForm,
        [STRAIN]: showStrainForm
      }

      const action = actionsMap[catalogFormType]
      action && action(item)
    }
  };

  render() {
    console.log('this.props: ', this.props)
    return <Catalog {...this.props} />
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogContainer)

export default withRouter(reduxContainer)
